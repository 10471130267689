//
// Custom opacity helpers
//

// Responsive opacity
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @for $opacity from 1 through 100 {
            .opacity#{$infix}-#{$opacity} {
                opacity: $opacity / 100 !important;
            }

            .hover-opacity#{$infix}-#{$opacity} {
                transition: $transition;

                &.active,
                &:hover {
                    transition: $transition;
                    opacity: $opacity / 100 !important;
                }
            }
        }
    }
}
