.fixtures-count-wrapper {
    position: relative;

    .white-line {
        position: absolute;
        width: calc(100% - 2rem);
        background: #fff;
        height: 1px;
        left: 50%;
        transform: translateX(-50%);
        top: 50%;
    }

    .fixtures-count-item {
        position: relative;

        &:nth-child(6n + 5):before {
            display: none;
        }

        &:before {
            position: absolute;
            content: '';
            height: calc(100% - 1rem);
            width: 1px;
            right: 0;
            background: #fff;
            top: 50%;
            transform: translateY(-50%);
        }

        &.active {
            background: url('/media/pages/dashboard/today_border.png');
            position: relative;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }
}

.fixtures-total-count-wrapper {
    background: url('/media/pages/sportstree/info_panel/bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    svg {
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
    }
}
