.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

[class*='Mui'] {
    outline: 0 !important;
}

.MuiSelect-select.MuiSelect-select {
    max-width: 110px;
}

.aside-menu {
    background: transparent;
}

.aside-menu-wrapper {
    background: linear-gradient(45deg, #5f1835, #0f2d64);
}

.brand .brand-logo {
    img {
        width: 100%;
        padding-right: 25px;
    }
}

@media (min-width: 992px) {
    .brand-dark .brand .btn .svg-icon svg g path {
        fill: #0658f0 !important;
        opacity: 1;
    }

    .brand-dark .brand .btn .svg-icon svg g path:last-child {
        fill: #f61457 !important;
        opacity: 1;
    }
}

.bg-color-primary {
    background-color: $primary;

    &:hover {
        background-color: $primary-hover;
    }
}
