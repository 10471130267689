//
// Label
//

@mixin label-size($size, $font-size) {
    height: $size;
    width: $size;
    font-size: $font-size;

    &.label-inline {
        width: auto;
    }
}

@mixin label-dot-size($size) {
    line-height: $size;
    min-height: $size;
    min-width: $size;
    height: $size;
    width: $size;
}
