/* @tailwind base; */

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.bg-transparent {
  background-color: transparent
}

.bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.rounded {
  border-radius: 0.25rem
}

.rounded-full {
  border-radius: 9999px
}

.border-none {
  border-style: none
}

.border {
  border-width: 1px
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.flex {
  display: flex
}

.table {
  display: table
}

.hidden {
  display: none
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-col-reverse {
  flex-direction: column-reverse
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.flex-1 {
  flex: 1 1 0%
}

.flex-shrink-0 {
  flex-shrink: 0
}

.order-1 {
  order: 1
}

.font-light {
  font-weight: 300
}

.font-medium {
  font-weight: 500
}

.font-bold {
  font-weight: 700
}

.font-black {
  font-weight: 900
}

.h-4 {
  height: 1rem
}

.h-6 {
  height: 1.5rem
}

.h-auto {
  height: auto
}

.h-full {
  height: 100%
}

.text-xs {
  font-size: 0.75rem
}

.text-sm {
  font-size: 0.875rem
}

.text-base {
  font-size: 1rem
}

.text-xl {
  font-size: 1.25rem
}

.text-2xl {
  font-size: 1.5rem
}

.text-3xl {
  font-size: 1.875rem
}

.text-5xl {
  font-size: 3rem
}

.leading-7 {
  line-height: 1.75rem
}

.leading-none {
  line-height: 1
}

.m-0 {
  margin: 0
}

.m-1 {
  margin: 0.25rem
}

.m-2 {
  margin: 0.5rem
}

.m-4 {
  margin: 1rem
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem
}

.mb-0 {
  margin-bottom: 0
}

.mt-1 {
  margin-top: 0.25rem
}

.mr-1 {
  margin-right: 0.25rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mr-2 {
  margin-right: 0.5rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mr-3 {
  margin-right: 0.75rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.ml-3 {
  margin-left: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mr-4 {
  margin-right: 1rem
}

.mb-4 {
  margin-bottom: 1rem
}

.ml-4 {
  margin-left: 1rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mr-5 {
  margin-right: 1.25rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mb-10 {
  margin-bottom: 2.5rem
}

.max-w-screen-sm {
  max-width: 640px
}

.max-w-screen-md {
  max-width: 768px
}

.min-h-screen {
  min-height: 100vh
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.overflow-hidden {
  overflow: hidden
}

.p-0 {
  padding: 0
}

.p-1 {
  padding: 0.25rem
}

.p-2 {
  padding: 0.5rem
}

.p-4 {
  padding: 1rem
}

.p-8 {
  padding: 2rem
}

.p-10 {
  padding: 2.5rem
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.pt-0 {
  padding-top: 0
}

.pr-0 {
  padding-right: 0
}

.pb-0 {
  padding-bottom: 0
}

.pl-0 {
  padding-left: 0
}

.pt-1 {
  padding-top: 0.25rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pt-3 {
  padding-top: 0.75rem
}

.pr-3 {
  padding-right: 0.75rem
}

.pl-3 {
  padding-left: 0.75rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pr-5 {
  padding-right: 1.25rem
}

.pb-5 {
  padding-bottom: 1.25rem
}

.pt-10 {
  padding-top: 2.5rem
}

.pb-10 {
  padding-bottom: 2.5rem
}

.pt-12 {
  padding-top: 3rem
}

.pointer-events-none {
  pointer-events: none
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.resize {
  resize: both
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
}

.shadow-outline {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
}

.shadow-none {
  box-shadow: none
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-justify {
  text-align: justify
}

.text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.uppercase {
  text-transform: uppercase
}

.visible {
  visibility: visible
}

.w-4 {
  width: 1rem
}

.w-6 {
  width: 1.5rem
}

.w-8 {
  width: 2rem
}

.w-48 {
  width: 12rem
}

.w-64 {
  width: 16rem
}

.w-auto {
  width: auto
}

.w-1\/5 {
  width: 20%
}

.w-full {
  width: 100%
}

.z-10 {
  z-index: 10
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@media (min-width: 768px) {

  .md\:flex {
    display: flex
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:mr-20 {
    margin-right: 5rem
  }

  .md\:ml-20 {
    margin-left: 5rem
  }

  .md\:w-24 {
    width: 6rem
  }

  .md\:w-40 {
    width: 10rem
  }

  .md\:w-1\/3 {
    width: 33.333333%
  }

  .md\:w-1\/4 {
    width: 25%
  }
}

@media (min-width: 1024px) {

  .lg\:w-1\/5 {
    width: 20%
  }
}
