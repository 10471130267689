//
// Loader
//

// Config
$loader-size: 1.4rem;
$loader-width: 2px;
$loader-size-sm: 1rem;
$loader-width-sm: 1px;
$loader-size-lg: 2rem;
$loader-width-lg: 3px;

// Base
.loader {
    display: inline-block;
    margin: 0 auto;
    border-radius: 100%;
    background-color: $gray-100;
    opacity: 0;
    animation: animation-loader 0.9s infinite ease-in-out;
    width: $loader-size;
    height: $loader-size;

    .btn & {
        position: relative;
        line-height: 0;
    }

    &.loader-lg {
        width: $loader-width-lg;
        height: $loader-width-lg;
    }

    &.loader-sm {
        width: $loader-width-sm;
        height: $loader-width-sm;
    }

    // Colors
    @each $name, $color in $theme-colors {
        &.loader-#{$name} {
            background-color: $color;
        }
    }
}

// Spinner animation
@keyframes animation-loader {
    0% {
        opacity: 0.3;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
