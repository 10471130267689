.graph-stats-wrapper {
    .left-section {
        display: flex;
        align-items: center;
    }

    .right-section {
        display: flex;
        align-items: center;
    }

    .middle-section {
        justify-content: center;
    }

    @media (max-width: 1440px) {
        flex-wrap: wrap;

        .middle-section {
            order: 1;
            flex: 0 0 100%;
        }

        .left-section {
            order: 2;
        }

        .right-section {
            order: 3;
        }
    }
}
