//
// Aside dark theme
//

// Initialization of global variables, mixins and functions
@import '../../../init';

.aside {
    background-color: get($layout-themes, dark);

    // Aside Menu
    .aside-menu {
        // Scrollbar
        @include perfect-scrollbar-theme(#637099);
    }
}

// Build aside menu theme
@include menu-ver-build-theme($aside-menu-config, dark);

// Modify icon color for flaticon2 icons
.aside {
    .aside-menu {
        > .menu-nav {
            .menu-item {
                > .menu-link:not(.menu-link-active):not(.menu-link-here):not(.menu-link-hover) {
                    [class^='flaticon2-'],
                    [class*=' flaticon2-'] {
                        color: #434d6b;
                    }
                }
            }
        }
    }
}
