.fixture-wrapper,
.head-to-head-wrapper {
    overflow: hidden;
    .banner-wrapper {
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        .match-number-bg {
            position: absolute;
            width: 4.5rem;
            height: 4.5rem;
            top: -3rem;
            transform: rotate(-45deg);
            background: #222;

            &.not_started {
                background-color: #4a7dff;
            }

            &.live {
                background-color: #27ae60;
            }

            &.ended {
                background-color: #eb5757;
            }
        }

        .match-number {
            position: absolute;
            top: 0;
            color: #fff;
        }

        .home-crest,
        .away-crest {
            flex: 0 0 10rem;
            img {
                object-fit: cover;
                height: 100%;
                margin: 0;
                width: 100%;
            }
        }

        .transparent-bg {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .home-crest {
            .transparent-bg {
                background: linear-gradient(270deg, #ffffff 20%, #ffffffcc 80%);
            }
        }

        .away-crest {
            .transparent-bg {
                background: linear-gradient(90deg, #ffffff 20%, #ffffffcc 80%);
            }
        }

        .participant-wrapper {
            display: flex;
            justify-content: center;
            flex: 0 0 40%;
            width: 0;

            .crest-wrapper {
                flex: 0 0 35%;
            }

            .score {
                font-size: 3rem;
                font-weight: bold;

                &.live {
                    color: #27ae60;
                }

                &.ended {
                    color: #eb5757;
                }
            }

            img {
                width: 100%;
            }
        }

        .date-time-wrapper {
            flex: 1;
        }

        .stats-wrapper {
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -1rem;
            z-index: 1;

            .games-played-btn {
                position: absolute;
                bottom: -0.8rem;
                padding: 0 0.5rem;
                font-size: 0.75rem;
                color: #fff;
                font-weight: bold;
                background: linear-gradient(90deg, rgb(0, 93, 255), rgb(255, 23, 88));
            }

            .stats-wrapper {
                width: 4.2rem;
                height: 4.2rem;
            }

            .games-played-number {
                width: 4rem;
                height: 4rem;
                transition: transform 0.3s;

                .games-played-info-number {
                    top: -0.5rem;
                }

                &:hover {
                    transform: scale(1.1);
                }

                &:before {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    bottom: -0.75rem;
                    border: solid #4a7dff;
                    border-width: 0 3px 3px 0;
                    padding: 3px;
                    transform: rotate(45deg);
                }
            }
        }
    }
}
