.gt-border {
    border: 1px solid transparent;
    background-image: linear-gradient(white, white), linear-gradient(to right, rgb(0, 93, 255), rgb(255, 23, 88));
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.gt-bg {
    background: linear-gradient(90deg, #005dff 10%, #b52eac 50%, #ff1758 100%);
}

span.gradient-color {
    font-size: 2rem;
    background: linear-gradient(90deg, rgb(0, 93, 255), rgb(255, 23, 88));
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

i.gradient-color {
    background: linear-gradient(90deg, rgb(0, 93, 255), rgb(255, 23, 88));
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.active-indicator:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #eee;
}

.color-insta {
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.color-linkedin {
    color: #0072b1;
}

.color-twitter {
    color: #1da1f2;
}
